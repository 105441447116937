

.logo{
    top: 14px;
    width: 100px;
    height: 43px;
    
}

.logoIn{
    top: 14px;
    width: 180px;
    height: 86px;
    
}
.comming{
    height: 600px;
}
.vbb{
    display: flex;
  }
  
  .container {
    width: 100%;
    margin: 15% auto;
  }
.dashArrow{
    cursor: pointer;
}
.boldIn{
 
width: 314px;
height: 86px;
text-align: right;
font: normal normal 800 70px/30px Montserrat;
letter-spacing: 0px;
color: #E3D8B6;
opacity: 1;
padding: 30px 0px;
}
.boldIn1{
 
    width: 314px;
    height: 86px;
    text-align: right;
    font: normal normal 800 70px/30px Montserrat;
    letter-spacing: 0px;
    color: #E3D8B6;
    opacity: 1;
    padding: 30px 0px;
    }
.subtitle{
    font-size: 20px !important;
    font-weight: 300 !important;
color: #131C4C;
opacity: 1;
}
.subValue{
    color: #131C4C;
    opacity: 1;
    font-size: 20px !important;
    font-weight: 600 !important;
}
.forget {
    font-size: 44px;
    width: 518px;
    height: 54px;
    /* UI Properties */
    text-align: left;
    font-family: "Montserrat";
    color:#131C4C;
}
.stamp{
    display: flex;
    justify-content: flex-end;
}
.thanku{
    display: flex;
    justify-content: center;
}
.headWords{
    font: normal normal 300 20px/57px Montserrat;
letter-spacing: 0px;
color: #131C4C;
opacity: 1;
}
.headValue{
    font: normal normal 500 20px Montserrat;
letter-spacing: 0px;
color: #131C4C;
opacity: 1;
}
.countdown{
    padding: 20px;

    margin-left: 265px;
}

.headIn{font:normal normal 300 20px Montserrat;
letter-spacing: 0px;
color: #131C4C;
display: block;
    text-align: center;
    display: contents;
}
.head{
 margin-top: 20px;
height: 33px;
/* UI Properties */
text-align: left;
font: normal normal 600 27px/27px Montserrat;
letter-spacing: 0px;
color: #131C4C;
opacity: 1;
width:"100%"
}
.nameus{
    color: #EBE3CA;
}
.searchRes{
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
    font: normal normal bold 24px/62px Montserrat;
letter-spacing: 0px;
color: #131C4C;
opacity: 1;
}
.logo-grid{
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .exMob{
        display: flex;
    }
    .wdialog{
      width: 20%;  
    }
    .searchRes{
        margin-top: 0px;
    }
    .boldIn1{
        display: none !important;
    }
    .newTable{
        overflow-x: auto;
        display: grid;
    }
    .spanned{
        font-size: 30px !important;
    }
}

/* marginLeft: "50px",marginRight:"60px" */
.background-1{
    max-height: 404px;
    /* margin-top: 50px; */
    margin-left: 50px;
    /* width: auto; */
    min-width: 95%;
    display: flex;
    object-fit: fill;

    /*width: 100%;
    height: 385px;
    margin-top: 50px;
    margin-left: 50px;
    object-fit: scale-down;*/

   /* height: 487px;
    width: 657px;
    /*margin-left: 72px;*/
}
.notification{
    display: inherit;
    padding-top: 33px !important;
}
.activated{
    color: #141C4B !important;
}
.pagination.Mui-disabled{
    border: 1px solid #131C4C;
    border-radius: 31px;
    padding: 10px 20px 10px 20px;
    background-color: white;
    color: green;
}
.pagination:disabled{
    border: 1px solid grey;
    border-radius: 31px;
    padding: 10px 20px 10px 20px;
    background-color: white;
    color: grey;
}
.pagination{
    border: 1px solid #131C4C;
    border-radius: 31px;
    padding: 10px 20px 10px 20px;
    background-color: white;
    color: #131C4C;
    cursor: pointer;
}

.noti-des{
    padding: 20px;
    width: 260px;
    
}
.notification-icon{
    width: 17px;
    height: 18px;
    margin-top: 2px;
}
.notification-icon2{
    width: 17px;
    height: 18px;
    margin-top: 8px;
}
.whole{
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    
}
.huge{
    padding-bottom: 15px;
    border-bottom: dashed 1px #636883;
}
.order{
    font-size: 11px;
    margin-left: 10px;
    color: #636883;
}
.orderval{
    color: #131C4C;
}
.half{
    margin-left: 2px;
    letter-spacing: 0px;
color: #131C4C;
opacity: 1;
font-size: 13px;
font-weight: 500;
}
.not-desc{
    text-align: left;
font-size: 18px;
font-weight: bold;
letter-spacing: 0px;
color: #131C4C;
opacity: 1;
}
.el-icon{
    width: 7px;
    height: 7px;
    margin-top: 5px;
}
.noti-icon{
    width: 17px;
    height: max-content;
}
.btn-1{
cursor: pointer;
}
.pending{
    text-align: left;
font: normal normal bold 24px/62px Montserrat;
letter-spacing: 0px;
color: #131C4C;
opacity: 1;
}
.MuiTypography-root{
    font-family: Montserrat !important;
}
.location-text{
  color: #131C4C;
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
  padding-top: 7px;
  padding-left: 50px;
}

.location{
    /*background:url('/public/Images/banner.png');*/
    background: #DBEAF4;
    border-bottom: solid 0.5px #C4C7D5;
    margin-top: 2px !important;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    font-size: 12px;
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input.active {
  border-color: yellow
}
.btn-noti{
    font-size: small;
    /*font-family: "Roboto","Helvetica","Arial",sans-serif;*/
}
.MuiListItemText-primary{
    font-family: Montserrat !important;
    font-weight: 700 !important;
    font-size: 24px !important;
}
.MuiListItemText-secondary{
    font-family: Montserrat !important;
    font-weight: 400 !important;
    font-size: 15px !important;
}
.auth-content-align{
    margin-top: 20px !important;
    margin-bottom: 10px;
    font-family:"Montserrat";
    text-align: center;

}
.popup{
    background-color: #131C4C;
}
.auth-content-align9{
    margin-top: 20px !important;
    margin-bottom: 10px;
    font-family:"Montserrat";
  

}
.auth-content-align5{

    font-family:"Montserrat";
    text-align: center;

}
.auth-content-align2{
    margin-top: 20px !important;
    margin-bottom: 10px;
    font-family:"Montserrat";
  

}
.auth-content-align25{
    margin-top: 5px !important;
    margin-bottom: 10px;
    font-family:"Montserrat";
  

}
.auth-content-align23{
   
    font-family:"Montserrat";
  

}


.tag-remove::before {
    content: 'x';
    color: #fff;
    font-weight: 300;
    font-family: Arial, sans-serif;
  }

#mdiv {
  width: 25px;
  height: 25px;
 

}
.closed{
    display: flex;
    justify-content: space-between;
}

.mdiv {
  height: 25px;
  width: 2px;
  margin-left: 12px;
  background-color: black;
  transform: rotate(45deg);
  Z-index: 1;
}

.md {
  height: 25px;
  width: 2px;
  background-color: black;
  transform: rotate(90deg);
  Z-index: 2;
}
.listed{
    list-style: disc !important;
}
.listed-inside{
    list-style: circle !important;
}
.MuiModal-root{
   border-radius: 10px;
   
}
::-webkit-scrollbar {
    width: 14px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #F0F0F0;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #D8D8D8;
  }
.odd{
    max-width: 1280px;
    margin-left: 25%;
    margin-right: 25%;
}
.auth-content-align1{
    margin-top: 20px !important;
    /* margin-bottom: 5px; */
    font-family:"Montserrat";
   /* width: 480px !important;*/

}
.auth-content-align2{
    margin-top: -5px;
    margin-bottom: 25px;
    font-family:"Montserrat";
    /* width: 480px !important; */

}
.welcome {
    font-size: 44px;
    width: 218px;
    height: 54px;
    /* UI Properties */
    text-align: left;
    font-family: "Montserrat";
    color:#131C4C;
    margin-top: 20px;
    margin-bottom: 20px;
}
.welcome7 {
    font-size: 44px;
    width: 218px;
    height: 54px;
    /* UI Properties */
    text-align: left;
    font-family: "Montserrat";
    color:#131C4C;
    margin-top: 20px;
    margin-bottom: 20px;
}
.welcome1 {
    font-size: 44px;
    width: 507px;
    height: 54px;
    /* UI Properties */
    text-align: left;
    font-family: "Montserrat";
    color:#131C4C;
}
.welcome2 {
    display: contents;
    font-size: 44px;
    width: 565px;
    height: 54px;
    /* UI Properties */
    text-align: left;
    font-family: "Montserrat";
    color:#131C4C;
}
.newpass{
    margin-top: 20px !important;
    margin-bottom: 10px;
    font-family:"Montserrat";
    color: #131C4C !important;
    opacity: 0.6;
    width: 500px;
}
.center{
    text-align: center !important;
    width: 40px;
}
.feedback{
    background-color: #F9DFE4;
    height: 200px;
    width: 80%;
    padding: 50px;
    border-radius: 35px;
    background: #F9DFE4 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000010;
}
.submit{
    color:"#131C4C !important"
}
.flexed{
    display: flex;
}
.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 800 !important;
    height: 100% !important;
}
.contents{
    font-family:"Montserrat";
    margin-bottom:10px;
    color: #131C4C;;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family:"Montserrat"
}
.notedts{
   

}

html {
   /* font-family: 'Roboto', sans-serif;*/
}
.css-1xbszww {
    padding-top: 22px !important;
    font-size: 17px !important;
    
    color: #131C4C ;
    opacity: 1 !important;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}
.header{
    border-bottom: 1px solid #E2E8F0;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 3.5rem;
    height: 70px;
    column-gap: 200px;
}
.hamburger {
    display: none;
}
.dialogNew{
    width: 400px;
    height: 180px;
}

.toggledts.MuiToggleButtonGroup-grouped {
    background: #EBE3CA 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
}
.toggledts.Mui-selected {
    color: #E2E8F0 !important;
    background-color: #131C4C !important;
    border-radius: 9px;
opacity: 1;
}
.toggledts.MuiToggleButtonGroup-grouped:not(:last-of-type){
    border-top-right-radius: 9px !important;
    border-bottom-right-radius: 9px !important;
}
.toggledts.MuiToggleButtonGroup-grouped:not(:first-of-type){
    border-top-left-radius: 9px !important;
    border-bottom-left-radius: 9px !important;
}

.toggledts1.MuiToggleButtonGroup-grouped {
    background: #EBE3CA 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
}
.toggledts1.Mui-selected {
    color: #E2E8F0 !important;
    background-color: #131C4C !important;
    border-radius: 9px;
opacity: 1;
}
.toggledts1.MuiToggleButtonGroup-grouped:not(:last-of-type){
    border-top-right-radius: 9px !important;
    border-bottom-right-radius: 9px !important;
}
.toggledts1.MuiToggleButtonGroup-grouped:not(:first-of-type){
    border-top-left-radius: 9px !important;
    border-bottom-left-radius: 9px !important;
}


.toggledtsPrefer.MuiToggleButtonGroup-grouped {
    background: #EBE3CA 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
}
.toggledtsPrefer.Mui-selected {
    color: #E2E8F0 !important;
    background-color: #131C4C !important;
    border-radius: 9px;
opacity: 1;
}
.toggledtsPrefer.MuiToggleButtonGroup-grouped:not(:last-of-type){
    border-top-right-radius: 9px !important;
    border-bottom-right-radius: 9px !important;
}
.toggledtsPrefer.MuiToggleButtonGroup-grouped:not(:first-of-type){
    border-top-left-radius: 9px !important;
    border-bottom-left-radius: 9px !important;
    padding-left: 20px !important; 
}

.toggledtsPrefer2.MuiToggleButtonGroup-grouped {
    background: #EBE3CA 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
}
.toggledtsPrefer2.Mui-selected {
    color: #E2E8F0 !important;
    background-color: #131C4C !important;
    border-radius: 9px;
opacity: 1;
}
.toggledtsPrefer2.MuiToggleButtonGroup-grouped:not(:last-of-type){
    border-top-right-radius: 9px !important;
    border-bottom-right-radius: 9px !important;
}
.toggledtsPrefer2.MuiToggleButtonGroup-grouped:not(:first-of-type){
    border-top-left-radius: 9px !important;
    border-bottom-left-radius: 9px !important;
    padding-left: 20px !important; 
}

.toggledtsCar.MuiToggleButtonGroup-grouped {
    background: #EBE3CA 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
}
.toggledtsCar.Mui-selected {
    color: #E2E8F0 !important;
    background-color: #131C4C !important;
    border-radius: 9px;
opacity: 1;
}
.toggledtsCar.MuiToggleButtonGroup-grouped:not(:last-of-type){
    border-top-right-radius: 9px !important;
    border-bottom-right-radius: 9px !important;
}
.toggledtsCar.MuiToggleButtonGroup-grouped:not(:first-of-type){
    border-top-left-radius: 9px !important;
    border-bottom-left-radius: 9px !important;
    padding-left: 20px !important; 
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
  
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 6px 12px #0000001C !important;
border: 1px solid #BCC0D5 !important;
opacity: 1 !important;
border-radius: 9px !important;
}
/*.toggledts.MuiToggleButtonGroup-grouped {
    background: #EBE3CA 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
}
.toggledts.Mui-selected {
    color: #E2E8F0 !important;
    background-color: #131C4C !important;
    border-radius: 9px;
opacity: 1;
}*/
.pass{
    color: #131C4C !important;
    
    height: 62px;
    padding: 23px 0px !important;
    font-size: 15px;
    font-family: Montserrat !important;
}
.fields{
    padding-top: 10px;
    text-align: left;
font: normal normal bold 20px/26px Montserrat;
letter-spacing: 0px;
color: #131C4C;
padding-bottom: 90px;
}
.manage{
margin-top: 20px;
}
.tamper{
    text-align: center;
font: normal normal bold 16px/19px Montserrat;
letter-spacing: 0px;
color: #000000;
}
.jk{
   display: flex;
}
.prefered{
    margin-bottom: 20px;

}
.pret{
    color: #131C4C;
    font-size: 15px;
    display: inline-grid;
    font-weight: 600;
}
.edit{
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 10px;
    width: 80px;
    cursor: pointer;
  }
  
  
  .fields1{
    padding-top: 10px;
    text-align: left;
  font: normal normal bold 20px/26px Montserrat;
  letter-spacing: 0px;
  color: #131C4C;
  padding-bottom: 20px;
  }
.profile{
    font-size: 44px;
    margin-top: 20px;
    font-weight: bold;
}
.profilen{
    font-size: 44px;
    margin-top: 40px;
    font-weight: bold;
}
.showForgot{
    display: none;
}
.inner {
    width: 80%;
    height: 1px;
    margin: 0px auto;
    border-bottom: 1px solid #969696;
}
.valDoc{
    border: 1px solid #BCC0D5 !important;
    border-radius: 5px !important;
    opacity: 1;
    color: #131C4C !important;
    width: 33px;
    height: 35px;
}
.valDocLeft{
    border: 1px solid #BCC0D5 !important;
    border-radius: 5px !important;
    opacity: 1;
    color: #131C4C !important;
    width: 33px;
    height: 35px;
    margin-left: -1px !important;
}
.valDoc,.valDocLeft .MuiSvgIcon-root{
    font-size: 12px !important;
}
.toggledts{
    width: 70px !important;
    height: 62px !important;
    font-family: 'Montserrat' !important; 
    font-size: 15px !important;
    font-weight: 600 !important;
    margin-top: 20px !important;

   
}
.authForgoted{
    display: grid;
    text-align: center;
    margin: 60px;
}
.emailed{
    color: #131C4C;
    opacity: 1;
    font-size: 13px;
    font-weight: 700;    
}
.emailImg{
    height: 80px;
}
.toggledtsCar{
    width: 70px !important;
    height: 62px !important;
    font-family: 'Montserrat' !important; 
    font-size: 15px !important;
    font-weight: 600 !important;
 

}
.toggledtsNew{
    width: 70px !important;
    height: 62px !important;
    font-family: 'Montserrat' !important; 
    font-size: 15px !important;
    font-weight: 600 !important;

}
.toggledtsPrefer{
    width: 80px !important;
    height: 62px !important;
    font-family: 'Montserrat' !important; 
    font-size: 15px !important;
    font-weight: 600 !important;
}
.toggledtsPrefer2{
    width: 70px !important;
    height: 62px !important;
    font-family: 'Montserrat' !important; 
    font-size: 15px !important;
    font-weight: 600 !important;
    margin-top: 15px !important;
}
.toggledtsPrefer3{
    width: 40px !important;
    height: 40px !important;
    font-family: 'Montserrat' !important; 
    font-size: 15px !important;
    font-weight: 600 !important;
    margin-top: 15px !important;
}
.toggledtsPrefer3.MuiToggleButtonGroup-grouped {
    background: #EBE3CA 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
}
.toggledtsPrefer3.Mui-selected {
    color: #E2E8F0 !important;
    background-color: #131C4C !important;
    border-radius: 9px;
opacity: 1;
}
.toggledtsPrefer3.MuiToggleButtonGroup-grouped:not(:last-of-type){
    border-top-right-radius: 9px !important;
    border-bottom-right-radius: 9px !important;
}
.toggledtsPrefer3.MuiToggleButtonGroup-grouped:not(:first-of-type){
    border-top-left-radius: 9px !important;
    border-bottom-left-radius: 9px !important;
    padding-left: 20px !important; 
}
.toggledtsprefer3:active{
    background-color: #131C4C;
}
.toggledts1{
    width: 120px !important;
    height: 62px !important;
    font-family: 'Montserrat' !important; 
    font-size: 15px !important;
    font-weight: 600 !important;
   
}
.toggledts:active{
    background-color: #131C4C;
}
.toggledts1:active{
    background-color: #131C4C;
}
.toggledtsprefer:active{
    background-color: #131C4C;
}
.toggledtsprefer2:active{
    background-color: #131C4C;
}
.toggle-button-left{
    margin-right: -13px !important;
    z-index: 1;
}
.toggle-button-right{
    width: 80px !important;
}
/*.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    display: flex !important;
    justify-content: space-between !important;
}*/
.padd{
    padding-left: 10px;
}
.dropnew{
    display: flex !important;
    justify-content: space-between !important;
}

.contr{
    letter-spacing: 0px;
color: #6E759A;
opacity: 1;
padding-bottom: 18px;
}
.newed{
    color: #131C4C !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    font-size: 15px !important;
    font-weight: 600 !important;
    min-width: 39px !important;
}
.valdet{
    color: #6E759A;
    margin-right: 25px;
    font-size: 13px;
    font-family: "Montserrat";
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #101010;
}
.nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toggledtsSingle.MuiToggleButtonGroup-grouped {
background: #EBE3CA 0% 0% no-repeat padding-box;
border-radius: 9px;
opacity: 1;
}
.santa {
    display: block;
    max-width: 100%; /* Ensures the image doesn't exceed the width of its container */
    height: 30px; /* Maintains the image's aspect ratio */
    margin-top: 10px;
  }
.toggledtsSingle.Mui-selected {
color: #E2E8F0 !important;
background-color: #131C4C !important;
border-radius: 9px;
opacity: 1;
}
.toggledtsSingle.MuiToggleButtonGroup-grouped:not(:last-of-type){
border-top-right-radius: 9px !important;
border-bottom-right-radius: 9px !important;
}
.toggledtsSingle.MuiToggleButtonGroup-grouped:not(:first-of-type){
border-top-left-radius: 9px !important;
border-bottom-left-radius: 9px !important;
padding-left: 20px !important; 
}


.toggle-button-multiple{
margin-right: -13px !important;
z-index: 1;
}
.toggle-button-Multipleright{
width: 100px !important;
}
.toggledtsSingle{
    width: 100px !important;
    height: 62px !important;
    font-family: 'Montserrat' !important; 
    font-size: 15px !important;
    font-weight: 600 !important;
}
.nav-item {
    margin-left: 5rem;
    display: flex;
}
.nav-item1 {
    margin-left: 1rem;
    display: flex;
}
.passd2{
    background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #BCC0D5;
border-radius: 5px;
opacity: 1;
height: 45px;
display: flex;
align-items: center;
width: 105px;
}

.passd{
    background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #BCC0D5;
border-radius: 5px;
opacity: 1;
height: 35px;
display: flex;
align-items: center;
width: 105px;
}
.nav-item-last {
    margin-left: 8rem;
    display: flex;
}
.nav-item-last1 {
    margin-left: 12rem;
    display: flex;
}
.container {
    /* Border */
    border: 1px solid rgba(0, 0, 0, 0.3);

    /* Used to position the arrow */
    position: relative;
}

.container__arrow {
    /* Size */
    height: 16px;
    width: 16px;

    background-color: #fff;
    position: absolute;
}

.container__arrow--tl {
    /* Position at the top left corner */
    left: 32px;
    top: 0px;

    /* Border */
    border-left: 1px solid rgba(0, 0, 0, 0.3);
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    transform: translate(50%, -50%) rotate(45deg);
}


.nav-link{
    font-size: 1.6rem;
    font-weight: 400;
    color: #475569;
}

.nav-link:hover{
    color: #482ff7;
}

.nav-logo {
    font-size: 2.1rem;
    font-weight: 500;
    color: #482ff7;
    display: flex;
}
@media only screen and (max-width: 1300px) {
    .spanned{
        font-size: 4vw;
    }
    .nav-item-last{
        margin-left: 0px;
        display: none;
    }
    .nav-item {
        margin-left: 1.7rem;
        display: flex;
    }
}
.cont{
display: flex;
gap: 10px;
}
@media only screen and (max-width: 900px) {
    
    .dashThing{
        display: flex;
        justify-content: space-between;
    }
    .welcome7 {
        margin-left: 50px;
        margin-bottom: 0px !important; 
    }
    .dashDetails{
        margin-left: 15px;
    }
    .contents{
        margin-left: 50px;
    }
    .logo-grid{
        margin-left: 25px !important;
    }
    .location-text{
        padding-left: 18px !important;
    }
    .authForgot{
        margin-top: 0px !important;
    }
    .extra-for{
        display: contents !important;
       
    }
    .auth-content-align25{
        margin-top: 5px !important;
        margin-bottom: 10px;
        font-family:"Montserrat";
      
    
    }
    .cont{
        flex-direction:column;
        }
    .nav-item-last{
        margin-left: 0px;
        display: none;
    }
    .nav-menu {
        
        position: absolute;
        left: -150%;
        top: 5rem;
        flex-direction: column;
        background-color: #fff;
        width: 100%;
        border-radius: 10px;
        text-align: center;
        transition: 0.3s;
        box-shadow:
            0 10px 27px rgba(0, 0, 0, 0.05);
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        margin: 2.5rem 0;
    }

    .hamburger {
        display: block;
        cursor: pointer;
        margin-left:-10px;
    }
    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }
    .forget {
        font-size: 32px;
        width: 90%;
        height: 54px;
        /* UI Properties */
        text-align: left;
        font-family: "Montserrat";
        color:#131C4C;
        margin-bottom: 60px;
    }
    .jk{
        flex-direction: column;
    }
    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0rem 1.5rem;
        height: 70px;
        column-gap: 50px;
    }
    .background-1{
        max-width: 100%;
        margin-top: 50px;
        margin-left:50px;
        margin-right:0px;
     
    } 
 
 .stamp{
    display: flex;
    justify-content: center;
}


}
.address-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    max-width: 380px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    color: #333;
    flex-shrink: 0;
  }
  
  .address-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  
  .address-info {
    display: flex;
    align-items: center;
  }
  
  .checkbox {
    width: 18px;
    height: 18px;
    display: inline-block;
    border: 2px solid #007bff;
    border-radius: 4px;
    position: relative;
    margin-right: 8px;
  }
  
  .checkbox.checked {
    background-color: #007bff;
  }
  
  .checkbox.checked::after {
    content: '✓';
    color: #fff;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    font-size: 14px;
  }
  
  .address-title {
    font-weight: bold;
    color: #333;
    font-size: 16px;
    padding: 20px;
  }
  
  .remove-button {
    background: none;
    border: none;
    color: #999;
    cursor: pointer;
    font-size: 12px;
    text-decoration: underline;
  }
  
  .remove-button:hover {
    color: #ff3333;
  }
  
  .address-details {
    font-size: 14px;
    color: #666;
    line-height: 1.6;
    padding-left: 26px; /* Indentation to align with checkbox */
  }
  
  .address-details p {
    margin: 0;
  }
  .address-card-container {
    display: flex;
    overflow-x: auto;
    gap: 20px;
    padding: 10px;
  }
  .pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination-controls button {
    background: #f0f0f0;
    border: none;
    padding: 8px 12px;
    margin: 0 10px;
    cursor: pointer;
    font-size: 16px;
}

.pagination-controls span {
    font-weight: bold;
    font-size: 14px;
}