.loader1 {
    border: 16px solid #EBE3CA; /* Light grey */
    border-top: 16px solid #131C4C; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin: auto;
    margin-top: 300px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .center21 {
    text-align: center !important;
    width: 480px;
    align-items: center;
   text-align: center;
    transform: translate(100%, 100%);
    padding: 10px;
    margin-bottom: 250px;
    }