.desc{
    font-size: 24px;
    font-weight: 600;
   color: #131C4C;
    ;
}
.FormsTracked{
    display: flex;
    gap: 10px;
    padding-bottom: 30px;
}.flexed{
    display: flex;
    gap: 10px;

  }
  @media only screen and (max-width: 600px) {
    .FormsTracked{
      display: block;
      padding-bottom: 30px;
  }
  .forms-spec{
    margin-bottom: 10px !important;
  }
  }
  .search{
    color: #131c4c47 !important;
    height: 100px;
    font-size: 13px;
    font-family: Montserrat !important;
    font-weight: 400;
    border-radius: 9px !important;
  }
  .canvasTrack{
    border: 1px solid #131c4c47 !important;
border-radius: 20px;
   display: flex;
 
  }
  .eraser{
    color: #131C4C;
opacity: 1;
font-weight: 600;
  }