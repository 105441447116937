
.loader {
    border: 16px solid #EBE3CA; /* Light grey */
    border-top: 16px solid #131C4C; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;

  }
  @media only screen and (max-width: 600px) {
    .loader{
     display: flex;
    }
}
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .center2 {
    text-align: center !important;
    width: 480px;
    align-items: center;
   text-align: center;
    transform: translate(100%, 100%);
    padding: 10px;
    margin-bottom: 250px;
    }
    .center4 {
      text-align: center !important;
      width: 480px;
      align-items: center;
     text-align: center;
      transform: translate(90%, 100%);
      padding: 10px;
      margin-bottom: 250px;
      }
    .center3 {
      text-align: center !important;
      width: 480px;
      align-items: center;
     text-align: center;
      transform: translate(85%, 100%);
      padding: 10px;
      margin-bottom: 250px;
      }
    .tick{
      height: 18px;
    }
    