.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.form {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.main {
  display: block;
  width: 1250px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}
.labelled {
  color: #131c4c;
  cursor: pointer;
  display: flex;
  font-size: 24px;
  font-family: Montserrat !important;
  font-weight: 700;
  padding-bottom: 20px;
  padding-top: 20px;
  justify-content: space-between;
}
.labelled-config {
  color: #131c4c;
  cursor: pointer;
  display: flex;
  font-size: 30px;
  font-family: Montserrat !important;
  font-weight: 700;
  padding-bottom: 20px;
  padding-top: 20px;
  justify-content: space-between;
}
.labelled-config-create {
  color: #131c4c;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  font-family: Montserrat !important;
  font-weight: 700;
  padding-bottom: 20px;
  padding-top: 20px;
  justify-content: space-between;
}
.CardContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.dash-config {
  display: flex;
  justify-content: space-between;
}
.iot-start {
  padding-right: 20px;
}
.Card-config {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.1);
  width: 250px;
  height: 120px !important;
  margin: 10px;
  cursor: pointer;
}
.Card-config:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border: solid 2px #131c4c;
}
.Card-config h3 {
  margin: 0 0 10px;
}

.Card-config p {
  margin: 0;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}
