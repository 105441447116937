.downloadable-image-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 20px;
  }
  
  .image-wrapper {
    position: relative;
    margin-top: 20px;
  }
  
 
  .download_sec{
    padding-left: 20px;
  }
  .download-button {
  
    
 
    padding: 10px 20px;
    font-size: 16px;
    background-color: #131C4C;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 20px;
    opacity: 1;
    transition: opacity 0.2s;
  }
  
  .image-wrapper:hover .download-button {
    opacity: 1 !important; 
  }
  
  .download-button:hover {
    background-color: #131C4C;
    opacity: 0.8;
  }
  